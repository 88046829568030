export const dossier_bl_details = [
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '1.Fret Surcharge',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '2.Frais Débarquement MICTSL s/v facture n°',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '3.Frais Magasinage MICTSL s/v facture n°…..........',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '4.Honoraires sur constat…....................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '5.Frais de dossier à l\'import …..............',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'DROITS ET TAXES                    IM4 n°                du ',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '6.Douanes :                       DAD…....................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'D.D….....................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'T.V.A…....................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'P.G.N…...................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'T.V.G…...................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'O.V…......................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'R.F.M…..................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'A.R.S…...................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'D.R.I…....................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 1,
        libeller: 'TRANSPORTS ET MANUTENTION',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '7.Location engins…...........................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '8.Transport TMM / TNR….................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '9 . Frais PHYTO….............................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '. Diveres interventions…...............................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: ' . Frais CAUTIONS MSK…................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 1,
        libeller: 'TRANSIT - LIVRAISON',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'Prestation sur constat…......................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: 'Honoraires …....................................................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '10. Commission de Transit….............................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: ' . Commission avance de fonds…....................................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '11.Dossier , imprimés , photocopies…...............................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '12.Transfert et mise en N/TP...........…...............................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
    {
        id_dossier_bl: '',
        is_title: 0,
        libeller: '13. T.V.A 20% sur….................…................',
        montant_tva: '',
        montant_htva: '',
        montant_ttc: '',
    },
]