import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('info_societe.page_titre'),

            crudform: {
                id: "",
                logo_soc: "",
                nom_soc: "",
                tel_soc: "",
                adresse_soc: "",
                mail_soc: "",
                nif_soc: "",
                stat_soc: "",
                rcs_soc: "",
                ville: "",
                quartier:"",
            },
            preview_link: "../../../public/assets/img/Logo-Tsaravidy.jpg",
            logo: null,
            show_progress: false,


        }
    },
    methods: {

        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.BASE_URL + "/infosociete/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");

                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        uploadImage(event) {
            this.show_progress = true;
            const URL = this.BASE_URL + '/infosociete/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImage(files[0]);

            let config = {
                header: {
                    // 'Content-Type': 'image/*',
                    // 'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    that.show_progress = false;
                    that.crudform.logo_soc = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },
        createImage(file) {
            var reader = new FileReader();
            var that = this;

            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        initLogo: function() {
            this.preview_link = "../../../public/assets/img/Logo-Tsaravidy.jpg";
            this.crudform.logo_soc = "";
            this.logo = null;
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/infosociete/get/").then(response => {
                //console.log(response.data[0]);
                this.crudform = response.data[0];
                if (this.crudform.logo_soc != '' && this.crudform.logo_soc != null) {
                    this.preview_link = this.BASE_URL + "/public/assets/img/" + this.crudform.logo_soc;
                }
            });
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.fetchData();
    },
    mounted: function() {


    }
}
