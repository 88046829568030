import Vue from 'vue'
// import Vuetable from 'vuetable-2'
// import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
// import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
// import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
// import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

// Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from 'moment'
import {dossier_bl_details} from "../../constant/constant_bl";

import {FormWizard, TabContent} from 'vue-form-wizard'


export default {
    components: {
        FormWizard,
        TabContent,
        // Vuetable,
        // VuetablePagination,
        // VuetableFieldCheckbox,
        // VuetableFieldMixin
    },
    data: function () {
        return {
            is_visible: true,
            value_etatDossier:'En cours',
            pageTitle: "Liste des tresoreries",
            base_url: this.BASE_URL,
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },

            Liste_compagnie_maritime: [],
            Ouverture: {
                id: this.$route.params.id_dossier == undefined ? 0 : this.$route.params.id_dossier,
                etat_dossier: 0,
                ouverture_date_dossier: moment().format('DD/MM/YYYY'),
                ouverture_date_dossier_formatted: moment().format('DD/MM/YYYY'),
                ouverture_numero_tc: [],
                ouverture_bl_attachement: '',
                ouverture_shipper: '',
                ouverture_bl_numero: "",
                ouverture_invoice_attachement: "",
                ouverture_invoice: '',
                ouverture_packing_list_attachement: "",
                ouverture_packing_list: '',
                ouverture_bsc_attachement: "",
                ouverture_circuit: [],
                ouverture_inspecteur: [],
                ouverture_fournisseur: '',
                ouverture_id_client: '',
                nom_client: '',
                ouverture_poids_brut: '',
                ouverture_poids_net: '',
                ouverture_nombre_colis: '',
                ouverture_nature_colis: '',
                ouverture_articles: '',
                ouverture_aet: '',
                ouverture_ge: '',
                ouverture_bsc: '',
                ecoterme: '',
                ouverture_dom: '',
                ouverture_dom_attachement: '',
                ouverture_id_ecoterme: '',
                ouverture_bsc_date_validation: '',
                ouverture_bsc_date_validation_formatted: '',
                ouverture_compagnie_maritime: '',
                ouverture_compapgnie_maritime_attachement: '',
                ouverture_fret_compagn: '',
                ouverture_navire: '',
                ouverture_prevision: '',
                ouverture_prevision_formatted: '',
                ouverture_commentaire: '',
                ouverture_id_compagnie_maritime: '',
                ouverture_date_arriver: '',
                ouverture_date_arriver_formatted: '',
                nom_compagnie_maritime: '',

                declaration_declaration: '',
                declaration_date: '',
                declaration_date_formatted: '',
                declaration_attachement: '',
                declaration_autres: '',
                declaration_douaneusd: '',
                declaration_commentaire: '',
                declaration_refov: '',
                declaration_date_depotbanque: '',
                declaration_date_depotbanque_formatted: '',
                declaration_etat_paiement: 'NON PAYER',
                declaration_ddtva: 0,
                declaration_tva: 0,
                taux_dd: '',
                taux_tva: '',
                declaration_pg: 0,
                declaration_ars: 0,
                declaration_apmf: 0,
                declaration_dad: 0,
                declaration_dc: 0,
                declaration_amde: 0,
                declaration_total_douane: 0,
                declaration_fobusd: 0,
                declaration_devise:0,
                montant_caisse: 0,
                solde: 0,
                chargement_frais_de_dossier: '',
                chargement_dossier_attachment: '',
                chargmenet_quotient: '',
                chargmenet_quotient_ht: '',
                chargmenet_quotient_htva: '',
                chargemnet_BAD: '',
                chargement_BAD_attachement: '',
                chargemnet_mictsl_debarg: '',
                chargement_mictsl_attachment: '',
                chargement_restitution: '',
                chargement_restitution_attachement: '',
                chargement_magasinage: '',
                chargement_magasinage_attachement: '',
                chargement_onverstays: '',
                chargement_surestarie: '',
                chargement_onverstays_attachement: '',
                chargement_surestarie_attachement: '',
                chargement_EHDN: '',
                chargement_EHDN_attachament: '',
                chargement_environnement: '',
                chargement_environnement_attachement: '',
                chargement_gate_remis: '',
                chargement_gate_remis_attachement: '',
                chargement_montant_frais_dossier: 0,
                chargement_montant_frais_dossier_ht: 0,
                chargement_montant_frais_dossier_htva: 0,
                chargement_montant_BAD: 0,
                chargement_montant_BAD_ht: 0,
                chargement_montant_BAD_htva: 0,
                chargement_montant_Mictsl: 0,
                chargement_montant_Mictsl_ht: 0,
                chargement_montant_Mictsl_htva: 0,
                chargement_montant_Restitution: 0,
                chargement_montant_Restitution_ht: 0,
                chargement_montant_Restitution_htva: 0,
                chargement_montant_magasisage: 0,
                chargement_montant_magasisage_htva: 0,
                chargement_montant_magasisage_ht: 0,
                chargement_montant_overstay: 0,
                chargement_montant_overstay_htva: 0,
                chargement_montant_overstay_ht: 0,
                chargement_montant_ehdn: 0,
                chargement_montant_ehdn_htva: 0,
                chargement_montant_ehdn_ht: 0,
                chargement_montant_environnement: 0,
                chargement_montant_environnement_htva: 0,
                chargement_montant_environnement_ht: 0,
                chargement_montant_surestarie: 0,
                chargement_montant_surestarie_htva: 0,
                chargement_montant_surestarie_ht: 0,
                chargement_montant_gate_remise_restitution: 0,
                chargement_montant_gate_remise_restitution_htva: 0,
                chargement_montant_gate_remise_restitution_ht: 0,
                chargement_cheqocesp_total: 0,
                chargement_cheqocesp_total_htva: 0,
                chargement_cheqocesp_total_ht: 0,
                chargement_photo_visite: '',
                chargement_piece_jointe_visite: '',
                chargement_nom_visite: '',
                chargement_commentaire: '',
                chargement_caution:'',
                chargement_caution_attachement:'',
                tc_en_visite: [],
                chargementsphotos: [],
                photo_visite: [],
                id_tresorerie: '',

                liquidation_date_r: '',
                liquidation_date_r_formatted: '',
                date_fermeture_overstay: '',
                date_fermeture_overstay_formatted: '',
                liquidation_date_l: '',
                liquidation_date_l_formatted: '',
                liquidation_montant_dc: 0,
                liquidation_montant_doc_attachement: '',
                liquidation_date_dc: '',
                liquidation_date_dc_formatted: '',
                liquidation_amende: '',
                liquidation_amende_attachement: '',
                liquidation_date_amende: '',
                liquidation_date_amende_formatted: '',
                liquidation_date_liquidation: '',
                liquidation_date_liquidation_formatted: '',
                liquidation_autre1: '',
                liquidation_autre2: '',
                liquidation_commentaire: '',
                liquidation: '',

                note_prix_date: '',
                note_prix_date_formatted: '',
                note_prix_commentaire: '',
                note_prix_frais_banque: 0,
                note_prix_autres_frais: 0,
                note_prix_transports: 0,
                dossier_tresorerie: [],
            },
            dossier_bl:{
                id_dossier: '',
                net_a_payer_total: '',
                ms: '',
                du: '',
                cnt_num: '',
                de: '',
                fact_numero: '',
                date_facture: '',
                a_client: '',
                nif_client: '',
                adresse_client: '',
                sous_total_tva: '',
                sous_total_htva: '',
                net_a_payer: '',
                date_generer_facture: '',
                date_generer_facture_formatted: moment().format('DD/MM/YYYY'),
            },
            article_Dossier: [
                {id: '',
                    reference_article: '',
                    designation_article: '',
                    hs_code_article: '',
                    description_article: '',
                    etat_suppression: '',
                    qte_article: '',
                    id_dossier: '',
                    id_article: '',
                    nom_entrepot: '',
                },
            ],
            dossier_bl_detail: dossier_bl_details,
            total_credit: 0,
            total_debit: 0,
            solde: 0,
            ENTREPOTS: [],
            btn_submit: false,
            // chargements: {
            //     tc_en_visite: [],
            //     chargementsphotos: [],
            //     photo_visite: [],
            //     frais_de_dossier:'',
            //     dossier_attachment:'',
            //     mictsl_debarg:'',
            //     mictsl_attachment:'',
            //     restitution:'',
            //     restitution_attachement:'',
            //     magasinage:'',
            //     magasinage_attachement:'',
            //     onverstays:'',
            //     onverstays_attachement:'',
            //     EHDN:'',
            //     EHDN_attachament:'',
            //     environnement:'',
            //     environnement_attachement:'',
            //     gate_remis:'',
            //     gate_remis_attachement:'',
            //     quotient:0,
            //     BAD:'',
            //     BAD_attachement:'',
            // },
            liquidation: {
                date_r: "",
                date_l: "",
                date_dc: "",
                montant_dc: "",
                amende_attachement: '',
                amende: '',
                montant_doc_attachement: '',
            },
            notedeprix: {},
            total_tc20: 0,
            total_tc40: 0,
            ouverture_inspecteur: [],
            nom_fichier_a_charger: '',
            ecoterme_list: [],
            client_list: [],
            article_list: [],
            selectedClient: {
                id: "",
                nom_client: "",
                tel_client: "",
                email_client: "",
                adresse_client: "",
                type_client: "PARTICULIER",
                nif: "",
                stat: "",
                rcs: "",
                cin_client: "",
            },
            tabpreview_link: [{lien: ''}],
            taux_echange: [],
            facture_detail: [],
            facture: {
                total_facture: 0,
                nom_client: '',
                id_client: '',
                article: '',
                poids_total: '',
                avance_client: 0,
                numero_declaration: '',
                numero_BL: '',
                date_facture: moment().format('DD/MM/YYYY'),
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_sortie: moment().format('DD/MM/YYYY'),
                date_sortie_formatted: moment().format('DD/MM/YYYY'),
                lieu_livraison: '',
                autre_numero: '',
                sous_total: '',
                montant_total: '',
                condition_reglement: '',
                montant1: 0,
                total_montant_htva:0,
                total_montant_ttc:0,
            },
            liste_tresorerie: [],
            info_societe:{},
            info_numero_facture: null,
        }
    },
    computed:{

        Is_Show(){
            return (this.$parent.role_user=='Super Administrateur' || this.$parent.role_user=='Administrateur' || this.$parent.role_user=='dévéloppeur') ? true : false ;
        }
    },
    methods: {
        fetchEntrepot() {
            axios.get(this.BASE_URL +'/entrepot/getall').then((response) => {
                this.ENTREPOTS = response.data;
            })
        },
        fetchArticle() {
            axios.get(this.BASE_URL +'/article/getall').then((response) => {
                this.article_list = response.data;
            })
        },

        GetINfoSociete(){
            axios.get(this.BASE_URL + '/infosociete/get').then((response) => {
                this.info_societe = response.data[0];
            })
        },
        pushArticle() {
            this.article_Dossier.push({
                reference_article: '',
                designation_article: '',
                hs_code_article: '',
                description_article: '',
                etat_suppression: '',
                qte_article: '',
                id_dossier: '',
                id_article: '',
                nom_entrepot: '',
            })
        },
        spliceArticle(index,rowData) {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                      if (value == true) {
                          this.btn_submit = true;
                          if (parseInt(rowData.id) > 0) {
                              let formulaire = new FormData();
                              formulaire.append('id_article', rowData.id_article);
                              formulaire.append('id_dossier', this.$route.params.id_dossier);
                              let link = this.BASE_URL + "/dossierarticle/deletearticle";
                              axios.post(link, formulaire).then((response)=> {
                                  this.btn_submit = false;
                                  if (response.data.error === "") {
                                      Vue.$toast.open({
                                          message: 'Suppression terminer avec succes!',
                                          type: 'success',
                                          position: 'top-right'
                                      });

                                  } else {
                                      Vue.$toast.open({
                                          message: response.data.error,
                                          type: 'error',
                                          position: 'top-right'
                                          // all of other options may go here
                                      });
                                  }
                              });
                          }

                          this.article_Dossier.splice(index, 1);
                      }

                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });

        },

        initFacture() {
            this.facture = {
                total_facture: 0,
                nom_client: '',
                id_client: '',
                article: '',
                poids_total: '',
                numero_declaration: '',
                numero_BL: '',
                date_facture: moment().format('DD/MM/YYYY'),
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_sortie: moment().format('DD/MM/YYYY'),
                date_sortie_formatted: moment().format('DD/MM/YYYY'),
                lieu_livraison: '',
                autre_numero: '',
                sous_total: '',
                montant_total: '',
                condition_reglement: '',
                montant1: 0,
                avance_client:0,
                total_montant_htva:0,
                total_montant_ttc:0,
            }
        },

        imprimerTresorerie() {
            window.open(this.BASE_URL + "/dossier/imprimertresorerie/" + this.Ouverture.id, '_blank');
        },
        imprimerFacture() {
            if (this.is_visible){
                window.open(this.BASE_URL + "/dossier/imprimerfacture/" + this.Ouverture.id, '_blank');
            }else{
                window.open(this.BASE_URL + "/dossier/imprimerfacturebl/" + this.Ouverture.id, '_blank');
            }
        },
        selectNomClient() {
            this.facture.nom_client = this.Ouverture.nom_client;
            this.facture.article = this.Ouverture.ouverture_articles;
            this.facture.poids_total = this.Ouverture.ouverture_poids_brut + 'KG';
            this.facture.numero_declaration = this.Ouverture.declaration_declaration;

            this.facture.numero_BL = this.Ouverture.ouverture_bl_numero;

            if (this.Ouverture.nom_client != '' && this.Ouverture.nom_client != null){
                this.selectedClient = this.client_list.find(
                    (client) => client.nom_client === this.Ouverture.nom_client
                );
            }
        },

        selectArticle(reference_article, index) {
            // Trouver l'article correspondant dans la liste
            const article = this.article_list.find(
                (item) => item.reference_article === reference_article
            );

            if (article) {
                this.article_Dossier[index].designation_article = article.designation_article;
                this.article_Dossier[index].hs_code_article = article.hs_code_article;
                this.article_Dossier[index].id_article = article.id_article;
            } else {
                // Réinitialiser les champs si l'article n'est pas trouvé
                this.article_Dossier[index].designation_article = '';
                this.article_Dossier[index].hs_code_article = '';
                this.article_Dossier[index].id_article = 0;
            }
        },

        pushDossierTresorerie() {
            this.Ouverture.dossier_tresorerie.push({
                id: 0,
                id_dossier: 0,
                libeller: '',
                montant_credit: 0,
                montant_debit: 0,
                type_mvt: '',
                date_tresorerie: '',
                date_tresorerie_formatted: '',
            })
        },
        spliceDossierTresorerie(index) {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    /*  if (value == true) {
                          var link = '';
                          this.btn_submit = true;
                          link = this.Ouverture.id == '' ? this.BASE_URL + "/dossier/addaction" : this.BASE_URL + "/dossier/editaction";
                          axios.post(link, $("#formulaire_ouverture").serialize()).then((response)=> {
                              this.btn_submit = false;
                              //this.message_reponse = response.data.msg;
                              if (response.data.error === "") {
                                  console.log("Enregistrement effectué avec succès!");
                                  Vue.$toast.open({
                                      message: 'Enregistrement effectué avec succès!',
                                      type: 'success',
                                      position: 'top-right'
                                      // all of other options may go here
                                  });
                                  this.getDossier(response.data.id_dossier)
                              } else {
                                  console.log("Erreur");
                                  Vue.$toast.open({
                                      message: response.data.error,
                                      type: 'error',
                                      position: 'top-right'
                                      // all of other options may go here
                                  });
                              }
                          });
                      }*/
                    this.Ouverture.dossier_tresorerie.splice(index, 1);
                    this.CalculSoldeTresorerie();
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });


        },

        CalculSoldeTresorerie() {
            var total_debit = 0, total_credit = 0;
            this.Ouverture.dossier_tresorerie.forEach(value => {
                total_debit += parseFloat(value.montant_debit);
                total_credit += parseFloat(value.montant_credit);
            });
            this.total_debit = total_debit;
            this.total_credit = total_credit;
            this.solde = total_credit
                - total_debit;

        },

        initFactureDetail() {

            this.facture_detail = [
                /*{
                    id_dossier: '',
                    is_title: 0,
                    libeller: '1.Fret Surcharge',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '2.Frais Débarquement MICTSL s/v facture n°',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '3.Frais Magasinage MICTSL s/v facture n°…..........',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '4.Honoraires sur constat…....................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '5.Frais de dossier à l\'import …..............',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'DROITS ET TAXES                    IM4 n°                du ',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '6.Douanes :                       DAD…....................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'D.D….....................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'T.V.A…....................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'P.G.N…...................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'T.V.G…...................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'O.V…......................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'R.F.M…..................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'A.R.S…...................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'D.R.I…....................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 1,
                    libeller: 'TRANSPORTS ET MANUTENTION',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '7.Location engins…...........................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '8.Transport TMM / TNR….................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '9 . Frais PHYTO….............................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '. Diveres interventions…...............................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: ' . Frais CAUTIONS MSK…................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 1,
                    libeller: 'TRANSIT - LIVRAISON',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'Prestation sur constat…......................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: 'Honoraires …....................................................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '10. Commission de Transit….............................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: ' . Commission avance de fonds…....................................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '11.Dossier , imprimés , photocopies…...............................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '12.Transfert et mise en N/TP...........…...............................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },
                {
                    id_dossier: '',
                    is_title: 0,
                    libeller: '13. T.V.A 20% sur….................…................',
                    montant_tva: '',
                    montant_htva: '',
                    montant_ttc: '',
                },*/



                {id: 0,
                    id_dossier: 0,
                    ref: 'BSC',
                    description: 'création',
                    montant: 0,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: 0,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SHIPPING COMPANY',
                    description: 'Frais de dossier',
                    montant: this.Ouverture.chargement_montant_frais_dossier_ht == 0 ? this.Ouverture.chargement_montant_frais_dossier : this.Ouverture.chargement_montant_frais_dossier_ht,
                    is_title: 0,
                    montant_htva: this.Ouverture.chargement_montant_frais_dossier_htva,
                    montant_ttc: this.Ouverture.chargement_montant_frais_dossier_ht == 0 ? this.Ouverture.chargement_montant_frais_dossier : parseFloat(this.Ouverture.chargement_montant_frais_dossier_htva) + parseFloat(this.Ouverture.chargement_montant_frais_dossier_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SHIPPING COMPANY',
                    description: 'caution',
                    montant: this.Ouverture.chargmenet_quotient_ht == 0 ? this.Ouverture.chargmenet_quotient : this.Ouverture.chargmenet_quotient_ht,
                    is_title: 0,
                    montant_htva: this.Ouverture.chargmenet_quotient_htva,
                    montant_ttc: this.Ouverture.chargmenet_quotient_ht == 0 ? this.Ouverture.chargmenet_quotient : parseFloat(this.Ouverture.chargmenet_quotient_htva) + parseFloat(this.Ouverture.chargmenet_quotient_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'MICTSL',
                    description: 'DEBARQUEMENT',
                    montant: this.Ouverture.chargement_montant_Mictsl_ht == 0 ? this.Ouverture.chargement_montant_Mictsl : this.Ouverture.chargement_montant_Mictsl_ht,
                    is_title: 0,
                    montant_htva: this.Ouverture.chargement_montant_Mictsl_htva,
                    montant_ttc: this.Ouverture.chargement_montant_Mictsl_ht == 0 ? this.Ouverture.chargement_montant_Mictsl : parseFloat(this.Ouverture.chargement_montant_Mictsl_htva) + parseFloat(this.Ouverture.chargement_montant_Mictsl_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'RESTITUTION',
                    description: 'RESTITUTION',
                    montant: this.Ouverture.chargement_montant_Restitution_ht == 0 ? this.Ouverture.chargement_montant_Restitution : this.Ouverture.chargement_montant_Restitution_ht,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.chargement_montant_Restitution_ht == 0 ? this.Ouverture.chargement_montant_Restitution : parseFloat(this.Ouverture.chargement_montant_Restitution_htva) + parseFloat(this.Ouverture.chargement_montant_Restitution_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: '',
                    description: 'magasinage',
                    montant: this.Ouverture.chargement_montant_magasisage_ht == 0 ? this.Ouverture.chargement_montant_magasisage : this.Ouverture.chargement_montant_magasisage_ht,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.chargement_montant_magasisage_ht == 0 ? this.Ouverture.chargement_montant_magasisage : parseFloat(this.Ouverture.chargement_montant_magasisage_htva) + parseFloat(this.Ouverture.chargement_montant_magasisage_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'OV',
                    description: 'TAXE + GN + ARS + APMF',
                    montant: this.Ouverture.declaration_total_douane,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.declaration_total_douane,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'BSC',
                    description: 'COMMISSION DE TRANSIT',
                    montant: 0,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: 0,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'surestarie',
                    description: 'surestarie',
                    montant: this.Ouverture.chargement_montant_surestarie_ht == 0 ? this.Ouverture.chargement_montant_surestarie : this.Ouverture.chargement_montant_surestarie_ht,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.chargement_montant_surestarie_ht == 0 ? this.Ouverture.chargement_montant_surestarie : parseFloat(this.Ouverture.chargement_montant_surestarie_htva) + parseFloat(this.Ouverture.chargement_montant_surestarie_ht),

                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'overstays',
                    description: 'overstays',
                    // montant: this.Ouverture.chargement_montant_overstay,
                    montant: this.Ouverture.chargement_montant_overstay_ht == 0 ? this.Ouverture.chargement_montant_overstay : this.Ouverture.chargement_montant_overstay_ht,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.chargement_montant_overstay_ht == 0 ? this.Ouverture.chargement_montant_overstay : parseFloat(this.Ouverture.chargement_montant_overstay_htva) + parseFloat(this.Ouverture.chargement_montant_overstay_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'ehdn',
                    description: 'ehdn',
                    // montant: this.Ouverture.chargement_montant_ehdn,
                    montant: this.Ouverture.chargement_montant_ehdn_ht == 0 ? this.Ouverture.chargement_montant_ehdn : this.Ouverture.chargement_montant_ehdn_ht,
                    is_title: 0,
                    montant_htva: 0,
                    // montant_ttc: this.Ouverture.chargement_montant_ehdn,
                    montant_ttc: this.Ouverture.chargement_montant_ehdn_ht == 0 ? this.Ouverture.chargement_montant_ehdn : parseFloat(this.Ouverture.chargement_montant_ehdn_htva) + parseFloat(this.Ouverture.chargement_montant_ehdn_ht),
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'AET',
                    description: 'AET',
                    montant: this.Ouverture.ouverture_aet,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.ouverture_aet,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PIM ',
                    description: 'PIM',
                    montant: this.Ouverture.ouverture_aet,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: this.Ouverture.ouverture_aet,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PHYTOSANITAIRE ',
                    description: 'PHYTOSANITAIRE',
                    montant: 0,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: 0,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SOUMISSION ',
                    description: 'SOUMISSION',
                    montant: 0,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: 0,
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PRELEVEMENT ',
                    description: 'PRELEVEMENT',
                    montant: 0,
                    is_title: 0,
                    montant_htva: 0,
                    montant_ttc: 0,
                },
            ];
            this.calculMontantFacture();
        },
        calculMontantFacture() {
            var total_montant = 0;
            var total_montant_tva = 0;
            var total_montant_ttc = 0;
            this.facture_detail.forEach(value => {
                value.montant_ttc = parseFloat(value.montant) + parseFloat(value.montant_htva);
                total_montant_tva+= parseFloat(value.montant_htva);
                total_montant_ttc+= parseFloat(value.montant_ttc);
                total_montant += parseFloat(value.montant);
            });
            this.facture.total_facture = total_montant;

            this.facture.total_montant_htva = total_montant_tva;
            this.facture.total_montant_ttc = total_montant_ttc  + parseFloat(this.facture.montant1);
            this.facture.sous_total = this.facture.total_montant_ttc;

            console.log("liste_facture",this.facture);
            this.facture.montant_total = this.facture.sous_total - parseFloat(this.facture.avance_client);
        },
        changeStatus_detail(index) {
            this.facture_detail[index].montant_ttc = 0;
            this.facture_detail[index].montant = 0;
            this.facture_detail[index].montant_htva = 0;
            this.calculMontantFacture();
        },
        pushFacture_detail() {
            this.facture_detail.push({
                id: 0,
                id_dossier: 0,
                ref: '',
                description: '',
                montant: 0,
                is_title: 0,
                montant_htva: 0,
                montant_ttc: 0,
            })
        },
        spliceDetail(index) {
            this.facture_detail.splice(index, 1);
        },
        changerEtaPaiement(etat_paiement) {
            this.Ouverture.declaration_etat_paiement = etat_paiement;
        },
        getTauxEchange() {
            axios.get(this.BASE_URL + '/tauxechange/fetchdata').then((response) => {
                this.taux_echange = response.data.tauxechange;
                this.Ouverture.declaration_devise = response.data.tauxechange[0].taux_montant;
            })
        },

        getTresorerie() {
            axios.get(this.BASE_URL + '/tresoreries/getalltresorerie').then((response) => {
                this.liste_tresorerie = response.data;
            })
        },

        calculMontantDD() {
            this.Ouverture.taux_dd = (this.Ouverture.taux_dd == '') ? 0 : this.Ouverture.taux_dd;
            this.Ouverture.declaration_douaneusd = (this.Ouverture.declaration_douaneusd == '') ? 0 : this.Ouverture.declaration_douaneusd;
            this.Ouverture.declaration_fobusd = (this.Ouverture.declaration_fobusd == '') ? 0 : this.Ouverture.declaration_fobusd;

            var montant_ariary = (parseFloat(this.Ouverture.declaration_douaneusd) + parseFloat(this.Ouverture.declaration_fobusd)) * this.Ouverture.declaration_devise;
            this.Ouverture.declaration_ddtva = (montant_ariary * parseFloat(this.Ouverture.taux_dd)) / 100 ?? 0;

            this.Ouverture.taux_tva = (this.Ouverture.taux_tva == '') ? 0 : this.Ouverture.taux_tva;

            var montant_ariary_tva = (parseFloat(this.Ouverture.declaration_douaneusd) + parseFloat(this.Ouverture.declaration_fobusd)) * this.Ouverture.declaration_devise;
            this.Ouverture.declaration_tva = (montant_ariary_tva * parseFloat(this.Ouverture.taux_tva)) / 100 ?? 0


            this.totalDOuane();
        },
        splicebl(index){
            this.dossier_bl_detail.splice(index, 1)
        },
        pushbl(index){
            var element = {
                id_dossier_bl: '',
                is_title: 0,
                libeller: '',
                montant_tva: 0,
                montant_htva: 0,
                montant_ttc: 0,
            };
            this.dossier_bl_detail.splice(index + 1, 0, element);
        },
        calculTotalNeta_payer () {
            let total_net_payer = 0, total_tva =0, total_htva = 0;
            this.dossier_bl_detail.forEach((value) => {
                value.montant_ttc = parseFloat(value.montant_tva) + parseFloat(value.montant_htva);
                total_tva += parseFloat(value.montant_tva);
                total_htva += parseFloat(value.montant_htva);
            });
            total_net_payer = total_htva + total_tva;
            this.dossier_bl.sous_total_htva = total_htva;
            this.dossier_bl.sous_total_tva = total_tva;
            this.dossier_bl.net_a_payer = total_net_payer;
        },
        /**
         * fonction permet de recuperer tout les dossier
         * @param id_dossier
         */
        getDossier(id_dossier) {
            axios.get(this.BASE_URL + '/dossier/get/' + id_dossier).then((response) => {
                this.Ouverture = response.data.dossier[0];

                    this.Ouverture.chargement_montant_frais_dossier_ht= this.Ouverture.chargement_montant_frais_dossier_ht <= 0 ? this.Ouverture.chargement_montant_frais_dossier : this.Ouverture.chargement_montant_frais_dossier_ht;
                    this.Ouverture.chargement_montant_BAD_ht = this.Ouverture.chargement_montant_BAD_ht <= 0 ? this.Ouverture.chargement_montant_BAD : this.Ouverture.chargement_montant_BAD_ht;
                    this.Ouverture.chargement_montant_Mictsl_ht = this.Ouverture.chargement_montant_Mictsl_ht <= 0 ? this.Ouverture.chargement_montant_Mictsl : this.Ouverture.chargement_montant_Mictsl_ht;
                    this.Ouverture.chargmenet_quotient_ht = this.Ouverture.chargmenet_quotient_ht <= 0 ? this.Ouverture.chargmenet_quotient : this.Ouverture.chargmenet_quotient_ht;
                    this.Ouverture.chargement_montant_Restitution_ht = this.Ouverture.chargement_montant_Restitution_ht <= 0 ? this.Ouverture.chargement_montant_Restitution : this.Ouverture.chargement_montant_Restitution_ht;
                    this.Ouverture.chargement_montant_magasisage_ht = this.Ouverture.chargement_montant_magasisage_ht <= 0 ? this.Ouverture.chargement_montant_magasisage : this.Ouverture.chargement_montant_magasisage_ht;
                    this.Ouverture.chargement_montant_overstay_ht = this.Ouverture.chargement_montant_overstay_ht <= 0 ? this.Ouverture.chargement_montant_overstay : this.Ouverture.chargement_montant_overstay_ht;
                    this.Ouverture.chargement_montant_ehdn_ht = this.Ouverture.chargement_montant_ehdn_ht <= 0 ? this.Ouverture.chargement_montant_ehdn : this.Ouverture.chargement_montant_ehdn_ht;
                    this.Ouverture.chargement_montant_environnement_ht = this.Ouverture.chargement_montant_environnement_ht <= 0 ? this.Ouverture.chargement_montant_environnement : this.Ouverture.chargement_montant_environnement_ht;
                    this.Ouverture.chargement_montant_surestarie_ht = this.Ouverture.chargement_montant_surestarie_ht <= 0 ? this.Ouverture.chargement_montant_surestarie : this.Ouverture.chargement_montant_surestarie_ht;
                    this.Ouverture.chargement_montant_gate_remise_restitution_ht = this.Ouverture.chargement_montant_gate_remise_restitution_ht <= 0 ? this.Ouverture.chargement_montant_gate_remise_restitution : this.Ouverture.chargement_montant_gate_remise_restitution_ht;
                    this.Ouverture.chargement_cheqocesp_total_ht = this.Ouverture.chargement_cheqocesp_total_ht <= 0 ? this.Ouverture.chargement_cheqocesp_total : this.Ouverture.chargement_cheqocesp_total_ht;

                this.is_visible = response.data.dossier[0].id_navire_bl == 0 ? true : false;
                this.value_etatDossier = (this.Ouverture.etat_dossier ==0 ) ? 'En cours' : 'Clôturer ';
                if (this.Ouverture.ouverture_numero_tc.length == 0)
                    this.Ouverture.ouverture_numero_tc = [{tc20: "", tc40: ""}];
                if (this.Ouverture.ouverture_circuit.length == 0)
                    this.Ouverture.ouverture_circuit = [{circuit: "", date_circuit: "", circuit_commentaire: ""}];
                if (this.Ouverture.ouverture_inspecteur.length == 0)
                    this.Ouverture.ouverture_inspecteur = [{nom_inspecteur: "", date_inspecteur: ""}];
                if (this.Ouverture.tc_en_visite.length == 0)
                    this.addNumTCEnVisite();
                if (this.Ouverture.photo_visite.length == 0)
                    this.addPhotoVisite();
                if (this.Ouverture.dossier_tresorerie.length == 0)
                    this.pushDossierTresorerie();
                this.CalculSoldeTresorerie();

                if (response.data.dossier[0].facture.length <= 0) {
                    this.initFacture();
                    this.initFactureDetail();
                } else {
                    this.facture = response.data.dossier[0].facture[0];
                    this.facture_detail = response.data.dossier[0].facture_detail;
                    console.log("--------------------------")
                    console.log(this.facture_detail)
                    console.log("--------------------------")
                }
                this.dossier_bl = response.data.dossier_bl[0];
                this.dossier_bl_detail = response.data.dossier_bl_detail.length > 0 ? response.data.dossier_bl_detail : dossier_bl_details;
                this.dossier_bl.fact_numero = (this.dossier_bl.fact_numero == '' || this.dossier_bl.fact_numero == null) ?
                    this.info_numero_facture.num_facture : this.dossier_bl.fact_numero;

                this.selectNomClient();
                // this.initFactureDetail();
                this.article_Dossier = response.data.dossier_article;
                if (response.data.dossier_article.length <= 0){
                    this.article_Dossier = [
                        {id: '',
                            reference_article: '',
                            designation_article: '',
                            hs_code_article: '',
                            description_article: '',
                            etat_suppression: '',
                            qte_article: '',
                            id_dossier: '',
                            id_article: '',
                            nom_entrepot: '',
                        },
                    ];
                }
                this.calculTotalCOMPTE();
            })
        },
        onInput(value) {
            console.log("Input value:", value);
        },
        onChange(value) {
            console.log("Change value:", value);
        },
        changerEtatDossier() {
            this.Ouverture.etat_dossier = (this.Ouverture.etat_dossier == 0) ? 1 : 0;
            this.value_etatDossier = (this.Ouverture.etat_dossier ==0 ) ? 'En cours' : 'Clôturer ';
            axios.get(this.BASE_URL + "/dossier/changeetatdossier/"+this.Ouverture.id).then(response => {
                console.log(response);
            });
        },
        totalDOuane() {

            this.Ouverture.declaration_total_douane = parseFloat(this.Ouverture.declaration_ddtva) +
                parseFloat(this.Ouverture.declaration_ars) +
                parseFloat(this.Ouverture.declaration_pg) +
                parseFloat(this.Ouverture.declaration_apmf) +
                parseFloat(this.Ouverture.declaration_dad) +
                parseFloat(this.Ouverture.declaration_dc) +
                parseFloat(this.Ouverture.declaration_tva) +
                parseFloat(this.Ouverture.declaration_amde);
        },

        getCompagnieMaritime() {
            axios.get(this.BASE_URL + "/compagnie/fetchdata").then(response => {
                this.Liste_compagnie_maritime = response.data.compagnie;
            });
        },
        getEcoterme() {
            axios.get(this.BASE_URL + "/ecoterme/fetchdata").then(response => {
                this.ecoterme_list = response.data.ecoterme;
            });
        },
        getclient() {
            axios.get(this.BASE_URL + "/client/fetchdata").then(response => {
                this.client_list = response.data.client;
            });
        },
        openModal() {
            this.$bvModal.show("crudData");
        },
        closeModal() {
            this.$bvModal.hide("crudData");
        },

        /**
         *
         * fonction permet d'enregistre Ouverture
         */
        onSubmitOuverture() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.Ouverture.id == '' ? this.BASE_URL + "/dossier/addaction" : this.BASE_URL + "/dossier/editaction";
                        axios.post(link, $("#formulaire_ouverture").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onSubmiTresorerie() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savetresorerie";
                        axios.post(link, $("#formulaire_tresorerie").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitChargement() {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savechargement";
                        axios.post(link, $("#formulaire_chargement").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitDeclaration() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savedeclaration";
                        axios.post(link, $("#formulaire_declaration").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitLiquidation() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/saveliquidation";
                        axios.post(link, $("#formulaire_liquidation").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitNotePrix() {
            // let link = this.is_visible ?  this.BASE_URL + "/dossier/savefacture" :  this.BASE_URL + "/dossier/savefacturebl";
            let link = this.is_visible ?  this.BASE_URL + "/dossier/savefacturebl" :  this.BASE_URL + "/dossier/savefacturebl";

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {

                    if (value == true) {
                        this.btn_submit = true;
                        axios.post(link, $("#formulaire_note_prix").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                }).finally(()=> {
                this.btn_submit = false;
            });
        },
        onSubmit_() {

        },
        onSubmitArticle() {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossierarticle/addaction";
                        axios.post(link, $("#formulaire_article").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier);
                                this.fetchArticle();
                                this.fetchEntrepot();
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        chargePieceJointe(name) {
            this.nom_fichier_a_charger = name;
            $("#piece_jointe").trigger('click');
        },
        resetPiecejointe(name) {
            if (name == 'ouverture_bl_attachement') this.Ouverture.ouverture_bl_attachement = '';
            if (name == 'ouverture_invoice_attachement') this.Ouverture.ouverture_invoice_attachement = '';
            if (name == 'ouverture_packing_list_attachement') this.Ouverture.ouverture_packing_list_attachement = '';
            if (name == 'ouverture_bsc_attachement') this.Ouverture.ouverture_bsc_attachement = '';
            if (name == 'declaration_attachement') this.Ouverture.declaration_attachement = '';
            if (name == 'chargement_dossier_attachment') this.Ouverture.chargement_dossier_attachment = '';
            if (name == 'chargement_mictsl_attachment') this.Ouverture.chargement_mictsl_attachment = '';
            if (name == 'chargement_restitution_attachement') this.Ouverture.chargement_restitution_attachement = '';
            if (name == 'chargement_magasinage_attachement') this.Ouverture.chargement_magasinage_attachement = '';
            if (name == 'chargement_onverstays_attachement') this.Ouverture.chargement_onverstays_attachement = '';
            if (name == 'chargement_surestarie_attachement') this.Ouverture.chargement_surestarie_attachement = '';
            if (name == 'chargement_EHDN_attachament') this.Ouverture.chargement_EHDN_attachament = '';
            if (name == 'chargement_environnement_attachement') this.Ouverture.chargement_environnement_attachement = '';
            if (name == 'chargement_gate_remis_attachement') this.Ouverture.chargement_gate_remis_attachement = '';
            if (name == 'liquidation_amende_attachement') this.Ouverture.liquidation_amende_attachement = '';
            if (name == 'liquidation_montant_doc_attachement') this.Ouverture.liquidation_montant_doc_attachement = '';
            if (name == 'chargement_BAD_attachement') this.Ouverture.chargement_BAD_attachement = '';
            if (name == 'ouverture_dom_attachement') this.Ouverture.ouverture_dom_attachement = '';
            if (name == 'ouverture_compapgnie_maritime_attachement') this.Ouverture.ouverture_compapgnie_maritime_attachement = '';
            if (name == 'chargement_piece_jointe_visite') this.Ouverture.chargement_piece_jointe_visite = '';
            if (name == 'chargement_caution_attachement') this.Ouverture.chargement_caution_attachement = '';


            this.nom_fichier_a_charger = '';
        },
        createImagesVisite(file, index) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.tabpreview_link[index].lien = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        uploadImageVisite(event, index) {
            const URL = this.BASE_URL + '/import/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImagesVisite(files[0], index);

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    that.Ouverture.photo_visite[index].url = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },
        createImages(file) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        uploadImage(event) {
            const URL = this.BASE_URL + '/import/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImages(files[0]);

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    if (that.nom_fichier_a_charger == 'ouverture_bl_attachement')
                        that.Ouverture.ouverture_bl_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_invoice_attachement')
                        that.Ouverture.ouverture_invoice_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_packing_list_attachement')
                        that.Ouverture.ouverture_packing_list_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_bsc_attachement')
                        that.Ouverture.ouverture_bsc_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'declaration_attachement')
                        that.Ouverture.declaration_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_dossier_attachment')
                        that.Ouverture.chargement_dossier_attachment = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_mictsl_attachment')
                        that.Ouverture.chargement_mictsl_attachment = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_restitution_attachement')
                        that.Ouverture.chargement_restitution_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_magasinage_attachement')
                        that.Ouverture.chargement_magasinage_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_onverstays_attachement')
                        that.Ouverture.chargement_onverstays_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_surestarie_attachement')
                        that.Ouverture.chargement_surestarie_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_EHDN_attachament')
                        that.Ouverture.chargement_EHDN_attachament = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_environnement_attachement')
                        that.Ouverture.chargement_environnement_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_gate_remis_attachement')
                        that.Ouverture.chargement_gate_remis_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'liquidation_amende_attachement')
                        that.Ouverture.liquidation_amende_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'liquidation_montant_doc_attachement')
                        that.Ouverture.liquidation_montant_doc_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_BAD_attachement')
                        that.Ouverture.chargement_BAD_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_dom_attachement')
                        that.Ouverture.ouverture_dom_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_compapgnie_maritime_attachement')
                        that.Ouverture.ouverture_compapgnie_maritime_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_piece_jointe_visite')
                        that.Ouverture.chargement_piece_jointe_visite = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_caution_attachement')
                        that.Ouverture.chargement_caution_attachement = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },


        validerOuverture() {
            return true;
        },
        validerDeclaration() {
            return true;
        },
        validerChargement() {
            return true;
        },
        validerLiquidation() {
            return true;
        },
        validerDebours52() {
            return true;
        },
        validerArticle() {
            return true;
        },
        onComplete() {

        },
        addNumTC() {
            // if (this.Ouverture.ouverture_numero_tc.length < 10) {
            this.Ouverture.ouverture_numero_tc.push(
                {
                    tc20: "",
                    tc40: ""
                });

            // }
        },
        countTC() {
            console.log(this.total_tc20 + " -- " + this.total_tc40);
            var tc20 = 0;
            var tc40 = 0;
            $.each(this.Ouverture.ouverture_numero_tc, function (key, value) {
                console.log(value);
                if (value.tc20 != "") tc20 = Number(tc20) + 1;
                if (value.tc40 != "") tc40 = Number(tc40) + 1;
            });
            this.total_tc20 = tc20;
            this.total_tc40 = tc40;
        },
        addCircuit() {
            this.Ouverture.ouverture_circuit.push({circuit: "", date_circuit: "", circuit_commentaire: ""});
        },
        addInspecteur() {
            this.Ouverture.ouverture_inspecteur.push({nom_inspecteur: "", date_inspecteur: ""});
        },
        addNumTCEnVisite() {
            this.Ouverture.tc_en_visite.push(
                {
                    transporteur: "",
                    dep_camion: "",
                    lieut_livraison: "",
                    statut: "",
                    plomb_fermeture: "",
                    montant_ar: ""
                });
        },
        addPhotoVisite() {
            this.Ouverture.photo_visite.push(
                {
                    url: ""
                });
            this.tabpreview_link.push({lien: ''})
        },
        splicePhotos_visite(index) {
            this.Ouverture.photo_visite.splice(index, 1);
            this.tabpreview_link.splice(index, 1);
        },
        choosePhotoVisite(liste, index) {
            $('#photo_visite_' + index).trigger('click');
            liste;
        },

        removeCircuit(index) {
            this.Ouverture.ouverture_circuit.splice(index, 1);
        },

        removeInspecteur(index) {
            this.Ouverture.ouverture_inspecteur.splice(index, 1);
        },

        calculTotalCOMPTE() {
            this.Ouverture.chargement_montant_frais_dossier = parseFloat(this.Ouverture.chargement_montant_frais_dossier_htva) + parseFloat(this.Ouverture.chargement_montant_frais_dossier_ht);
            this.Ouverture.chargmenet_quotient = parseFloat(this.Ouverture.chargmenet_quotient_htva) + parseFloat(this.Ouverture.chargmenet_quotient_ht);
            this.Ouverture.chargement_montant_BAD = parseFloat(this.Ouverture.chargement_montant_BAD_htva) + parseFloat(this.Ouverture.chargement_montant_BAD_ht);
            this.Ouverture.chargement_montant_Mictsl = parseFloat(this.Ouverture.chargement_montant_Mictsl_htva) + parseFloat(this.Ouverture.chargement_montant_Mictsl_ht);
            this.Ouverture.chargement_montant_Restitution = parseFloat(this.Ouverture.chargement_montant_Restitution_htva) + parseFloat(this.Ouverture.chargement_montant_Restitution_ht);
            this.Ouverture.chargement_montant_magasisage = parseFloat(this.Ouverture.chargement_montant_magasisage_htva) + parseFloat(this.Ouverture.chargement_montant_magasisage_ht);
            this.Ouverture.chargement_montant_overstay = parseFloat(this.Ouverture.chargement_montant_overstay_htva) + parseFloat(this.Ouverture.chargement_montant_overstay_ht);
            this.Ouverture.chargement_montant_surestarie = parseFloat(this.Ouverture.chargement_montant_surestarie_htva) + parseFloat(this.Ouverture.chargement_montant_surestarie_ht);
            this.Ouverture.chargement_montant_ehdn = parseFloat(this.Ouverture.chargement_montant_ehdn_htva) + parseFloat(this.Ouverture.chargement_montant_ehdn_ht);
            this.Ouverture.chargement_montant_environnement = parseFloat(this.Ouverture.chargement_montant_environnement_htva) + parseFloat(this.Ouverture.chargement_montant_environnement_ht);
            this.Ouverture.chargement_montant_gate_remise_restitution = parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution_htva) + parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution_ht);

            this.Ouverture.chargement_cheqocesp_total_ht = parseFloat(this.Ouverture.chargement_montant_ehdn_ht)
                + parseFloat(this.Ouverture.chargement_montant_overstay_ht)
                + parseFloat(this.Ouverture.chargement_montant_magasisage_ht)
                + parseFloat(this.Ouverture.chargement_montant_Restitution_ht)
                + parseFloat(this.Ouverture.chargement_montant_Mictsl_ht)
                + parseFloat(this.Ouverture.chargement_montant_BAD_ht)
                + parseFloat(this.Ouverture.chargement_montant_frais_dossier_ht)
                + parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution_ht)
                + parseFloat(this.Ouverture.chargement_montant_environnement_ht)
                + parseFloat(this.Ouverture.chargement_montant_surestarie_ht)
                + parseFloat(this.Ouverture.chargmenet_quotient_ht);

            this.Ouverture.chargement_cheqocesp_total_htva = parseFloat(this.Ouverture.chargement_montant_ehdn_htva)
                + parseFloat(this.Ouverture.chargement_montant_overstay_htva)
                + parseFloat(this.Ouverture.chargement_montant_magasisage_htva)
                + parseFloat(this.Ouverture.chargement_montant_Restitution_htva)
                + parseFloat(this.Ouverture.chargement_montant_Mictsl_htva)
                + parseFloat(this.Ouverture.chargement_montant_BAD_htva)
                + parseFloat(this.Ouverture.chargement_montant_frais_dossier_htva)
                + parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution_htva)
                + parseFloat(this.Ouverture.chargement_montant_environnement_htva)
                + parseFloat(this.Ouverture.chargement_montant_surestarie_htva)
                + parseFloat(this.Ouverture.chargmenet_quotient_htva);

            this.Ouverture.chargement_cheqocesp_total = parseFloat(this.Ouverture.chargement_montant_ehdn)
                + parseFloat(this.Ouverture.chargement_montant_overstay)
                + parseFloat(this.Ouverture.chargement_montant_magasisage)
                + parseFloat(this.Ouverture.chargement_montant_Restitution)
                + parseFloat(this.Ouverture.chargement_montant_Mictsl)
                + parseFloat(this.Ouverture.chargement_montant_BAD)
                + parseFloat(this.Ouverture.chargement_montant_frais_dossier)
                + parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution)
                + parseFloat(this.Ouverture.chargement_montant_environnement)
                + parseFloat(this.Ouverture.chargement_montant_surestarie)
                + parseFloat(this.Ouverture.chargmenet_quotient)
        },
        getNumeroFacture() {
            axios.get(this.BASE_URL + '/dossier/generatefacturejson').then((response) => {
                this.info_numero_facture = response.data;
            })
        }
    },

    watch: {
        // "Ouverture.ouverture_numero_tc.tc20": function() {
        //     this.countTC();
        // },
        // "Ouverture.ouverture_numero_tc.tc40": function() {
        //     this.countTC();
        // }
    },
    created: function () {
        this.$emit('change-page', this.pageTitle);
        if (this.Ouverture.ouverture_numero_tc.length == 0)
            this.Ouverture.ouverture_numero_tc = [{tc20: "", tc40: ""}];
        if (this.Ouverture.ouverture_circuit.length == 0)
            this.Ouverture.ouverture_circuit = [{circuit: "", date_circuit: "", circuit_commentaire: ""}];
        if (this.Ouverture.ouverture_inspecteur.length == 0)
            this.Ouverture.ouverture_inspecteur = [{nom_inspecteur: "", date_inspecteur: ""}];
        if (this.Ouverture.tc_en_visite.length == 0)
            this.addNumTCEnVisite();
        if (this.Ouverture.photo_visite.length == 0)
            this.addPhotoVisite();


        this.getCompagnieMaritime();
        this.getEcoterme();
        this.getclient();
        this.getTauxEchange();
        this.getTresorerie();
        this.pushDossierTresorerie();
    },
    mounted: function () {
        this.initFactureDetail();
        this.GetINfoSociete();
        this.validerDeclaration();
        this.getNumeroFacture();
        this.fetchArticle();
        this.fetchEntrepot();

        if (parseInt(this.$route.params.id_dossier) > 0) {

            this.getDossier(this.$route.params.id_dossier);
            this.dossier_bl.id_dossier = this.$route.params.id_dossier;
        }
    }
}
