import Vue from 'vue'

// import $ from 'jquery'
import axios from "axios";
import vSelect from 'vue-select'
// import $ from "jquery";

// Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)

export default {
    components: {

    },
    data: function () {
        return {
            filters: {
                voyage: '',
                heure_depart: '',
                heure_dechargement: '',
                qte_t: '',
                nbre_sac: '',
                nbre_big_bag: '',
                bill_of_lading: '',
                marque: '',
                bl: '',
                be: '',
                num_declaration: '',
                transporteur: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                delivery_time: ''
            },
            btn_submit: false,
            enlevement_details:[],
            numero_voyage: 0,
            ARTICLE: [],
            TRANSPORTEUR: [],
            NOM_CHAUFFEUR: [],
            NUM_CAMION: [],
            NUM_REMORQUE: [],
            NUM_CHAUFFEUR: [],
            DESTINATION: [],
            BILL_OF_LADING: [],
            LIST_TRANSPORTEUR: [],
            total: {
                total_qte_t: 0,
                total_nbre_sac: 0,
                total_nbre_big_bag: 0,
            },

        }
    },
    computed: {
        filteredEnlevementDetails() {
            if (!this.enlevement_details) return [];

            return this.enlevement_details.filter(item => {
                if (!item) return false;

                // Fonction helper pour vérifier les propriétés de manière sûre
                const safeIncludes = (value, searchStr) => {
                    if (!searchStr) return true;
                    if (value === null || value === undefined) return false;
                    return value.toString().toLowerCase().includes(searchStr.toLowerCase());
                };

                // Vérification sûre des propriétés
                const matchVoyage = safeIncludes(item.voyage, this.filters.voyage);
                const matchHeureDepart = safeIncludes(item.heure_depart, this.filters.heure_depart);
                const matchHeureDechargement = safeIncludes(item.heure_dechargement, this.filters.heure_dechargement);
                const matchBL = safeIncludes(item.bl, this.filters.bl);
                const matchBE = safeIncludes(item.be, this.filters.be);
                const matchTransporteur = safeIncludes(item.transporteur, this.filters.transporteur);
                const matchNomChauffeur = safeIncludes(item.nom_chauffeur, this.filters.nom_chauffeur);
                const matchPermis = safeIncludes(item.permis, this.filters.permis);
                const matchNumCamion = safeIncludes(item.num_camion, this.filters.num_camion);
                const matchNumRemorque = safeIncludes(item.num_remorque, this.filters.num_remorque);
                const matchNumChauffeur = safeIncludes(item.num_chauffeur, this.filters.num_chauffeur);
                const matchDestination = safeIncludes(item.destination, this.filters.destination);
                const matchDeliveryTime = safeIncludes(item.delivery_time, this.filters.delivery_time);

                // Vérification sûre des détails
                const details = item.details || [];
                const matchQteT = !this.filters.qte_t || details.some(detail =>
                    detail && safeIncludes(detail.qte_t, this.filters.qte_t)
                );
                const matchNbreSac = !this.filters.nbre_sac || details.some(detail =>
                    detail && safeIncludes(detail.nbre_sac, this.filters.nbre_sac)
                );
                const matchNbreBigBag = !this.filters.nbre_big_bag || details.some(detail =>
                    detail && safeIncludes(detail.nbre_big_bag, this.filters.nbre_big_bag)
                );
                const matchBillOfLading = !this.filters.bill_of_lading || details.some(detail =>
                    detail && detail.id_navire_bl && safeIncludes(this.getBillOfLadingLabel(detail.id_navire_bl), this.filters.bill_of_lading)
                );
                const matchMarque = !this.filters.marque || details.some(detail =>
                    detail && detail.id_dossier_article && safeIncludes(this.getArticleLabel(detail.id_dossier_article), this.filters.marque)
                );
                const matchNumDeclaration = !this.filters.num_declaration || details.some(detail =>
                    detail && safeIncludes(detail.num_ivoice, this.filters.num_declaration)
                );

                return matchVoyage && matchHeureDepart && matchHeureDechargement &&
                    matchQteT && matchNbreSac && matchNbreBigBag &&
                    matchBillOfLading && matchMarque && matchBL &&
                    matchBE && matchNumDeclaration && matchTransporteur &&
                    matchNomChauffeur && matchPermis && matchNumCamion &&
                    matchNumRemorque && matchNumChauffeur && matchDestination &&
                    matchDeliveryTime;
            });
        },
        filteredTotals() {
            const totals = {
                total_qte_t: 0,
                total_nbre_sac: 0,
                total_nbre_big_bag: 0
            };

            if (!this.filteredEnlevementDetails) return totals;

            this.filteredEnlevementDetails.forEach(item => {
                if (!item || !item.details) return;

                item.details.forEach(detail => {
                    if (!detail) return;

                    totals.total_qte_t += parseFloat(detail.qte_t || 0);
                    totals.total_nbre_sac += parseFloat(detail.nbre_sac || 0);
                    totals.total_nbre_big_bag += parseFloat(detail.nbre_big_bag || 0);
                });
            });

            return totals;
        }
    },
    methods: {
        // Helper pour vérifier si une valeur existe et n'est pas vide
        isValidValue(value) {
            return value !== null && value !== undefined && value !== '';
        },
        getBillOfLadingLabel(id) {
            const bl = this.BILL_OF_LADING.find(item => item.id === id);
            return bl ? bl.num_bl : '';
        },
        getArticleLabel(id) {
            const article = this.ARTICLE.find(item => item.id === id);
            return article ? article.reference_article : '';
        },
        calculTotal(index) {

            let total_qte_t = 0, total_nbre_sac = 0, total_nbre_big_bag = 0;
            this.enlevement_details[index].details.forEach(value => {
                total_qte_t+= parseFloat(value.qte_t);
                total_nbre_sac += parseFloat(value.nbre_sac);
                total_nbre_big_bag += parseFloat(value.nbre_big_bag)
            });
            this.total= {
                total_qte_t: total_qte_t,
                total_nbre_sac: total_nbre_sac,
                total_nbre_big_bag: total_nbre_big_bag
            };
        },
        fetchTransporteur() {
            axios.get(this.BASE_URL + "/transporteur/getall/").then(response => {
                this.LIST_TRANSPORTEUR = response.data;
            });
        },
        loadInfoTransporteur(index) {
            let formulaire = new FormData();
            this.enlevement_details[index].num_chauffeur = '';
            this.enlevement_details[index].num_remorque = '';
            this.enlevement_details[index].num_camion = '';
            this.enlevement_details[index].nom_chauffeur = '';
            formulaire.append('transporteur', this.enlevement_details[index].transporteur);
            axios.post(this.BASE_URL+"/transporteur/fetchelement/", formulaire).then(response => {
                this.enlevement_details[index].INFO_TRANSPORTEUR = response.data;
            })
        },

        fetchDetails(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevementdetail/getbysuivieenlevement/"+id_suivie_enlevement).then(response => {
                if (response.data.element.length > 0){
                    this.enlevement_details = response.data.element;
                    this.total = response.data.total;
                }else{
                    this.initiDetail();
                }
            });
        },

        fetchArticle(){
            let formulaire = new FormData();
            formulaire.append('id_suivie_enlevement',this.$route.params.id_suivie_enlevement)
            axios.post(this.BASE_URL+ "/suivieenlevementdetail/fetcharticle", formulaire).then((response) => {
                this.ARTICLE = response.data;
            })
        },

        getNumeroVoyage(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevementdetail/getnumerovoyage/"+id_suivie_enlevement).then(response => {
                this.numero_voyage = response.data;
                this.fetchDetails(this.$route.params.id_suivie_enlevement);
                this.fetchElement(id_suivie_enlevement);
            });
        },

        fetchElement(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevement/fetchelement/"+id_suivie_enlevement).then(response => {
                console.log(response.data);
                this.BILL_OF_LADING = response.data;
            });
        },

        fetchTranspoteur(){
            var champ = 'transporteur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.TRANSPORTEUR = response.data;
            });
        },
        fetchNomChauffeur(){
            var champ = 'nom_chauffeur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.NOM_CHAUFFEUR = response.data;
            });
        },
        fetchNumCamion(){
            var champ = 'num_camion';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.NUM_CAMION = response.data;
            });
        },
        fetchNumRemorque(){
            var champ = 'num_remorque';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.NUM_REMORQUE = response.data;
            });
        },
        fetchNumChauffeur(){
            var champ = 'num_chauffeur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.NUM_CHAUFFEUR = response.data;
            });
        },
        fetchDestination(){
            var champ = 'destination';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/"+ champ).then(response => {
                this.DESTINATION = response.data;
            });
        },
        initiDetail() {
            this.enlevement_details = [
                {
                    id: '',
                    id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                    voyage: this.numero_voyage,
                    heure_depart: '',
                    heure_dechargement: '',
                    qte_t: '',
                    nbre_sac: '',
                    nbre_big_bag: '',
                    marque: '',
                    id_dossier_article: '',
                    bl: this.numero_voyage,
                    be: '',
                    num_ivoice: '',
                    transporteur: '',
                    bill_of_lading: '',
                    nom_chauffeur: '',
                    permis: '',
                    num_camion: '',
                    num_remorque: '',
                    num_chauffeur: '',
                    destination: '',
                    delivery_time: '',
                    responsable_delivery: '',
                    INFO_TRANSPORTEUR: [],
                    details:[{
                        id: '',
                        id_suivie_enlevement_details: '',
                        nbre_sac: '',
                        qte_t: '',
                        nbre_big_bag: '',
                        marque: '',
                        num_ivoice: '',
                        id_dossier_article: '',
                        be: '',
                        bil_of_lading: '',
                        id_navire_bl: '',
                        LIST_ARTICLE: [],
                        LIST_TRANSPORTEUR: [],
                    }]
                }
            ]
        },
        loadElementforBL(index,index_detail, id_navire_bl) {
            let formulaire = new FormData();
            formulaire.append('id_navire_bl', id_navire_bl);
            axios.post(this.BASE_URL+"/suivieenlevement/loadelementforbl/", formulaire).then(response => {
                let navire_bl = response.data.navire_bl[0];
                this.enlevement_details[index].details[index_detail].LIST_ARTICLE = response.data.article;
                this.enlevement_details[index].details[index_detail].num_ivoice = navire_bl.declaration_bl;
            })
        },

        pushElement(index) {
            this.enlevement_details[index].details.push({
                id: '',
                id_suivie_enlevement_details: '',
                nbre_sac: '',
                qte_t: '',
                nbre_big_bag: '',
                marque: '',
                num_ivoice: '',
                id_dossier_article: '',
                bil_of_lading: '',
                be: '',
                id_navire_bl: '',
                LIST_ARTICLE: [],
                LIST_TRANSPORTEUR: [],
            })
        },
        spliceElement(index, index_detail) {
            this.enlevement_details[index].details.splice(index_detail, 1);
        },
        pushDetail() {
            this.numero_voyage = parseInt(this.numero_voyage) + 1;
            this.enlevement_details.push({
                id: '',
                id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                voyage: this.numero_voyage,
                heure_depart: '',
                heure_dechargement: '',
                qte_t: '',
                nbre_sac: '',
                nbre_big_bag: '',
                marque: '',
                id_dossier_article: '',
                bl: this.numero_voyage,
                be: '',
                num_ivoice: '',
                transporteur: '',
                bill_of_lading: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                delivery_time: '',
                responsable_delivery: '',
                INFO_TRANSPORTEUR: [],
                details:[{
                    id: '',
                    id_suivie_enlevement_details: '',
                    nbre_sac: '',
                    qte_t: '',
                    nbre_big_bag: '',
                    marque: '',
                    num_ivoice: '',
                    id_dossier_article: '',
                    bil_of_lading: '',
                    be: '',
                    id_navire_bl: '',
                    LIST_ARTICLE: [],
                    LIST_TRANSPORTEUR: [],
                }]
            })
        },
        spliceDetail(index, rowData) {
            let that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then( async (value) => {
                    if (value == true) {
                        if (rowData.id > 0) {
                            const response = await axios.post(that.BASE_URL + "/suivieenlevementdetail/delete/" + rowData.id);
                            if (response.data.error === ""){
                                this.numero_voyage = parseInt(this.numero_voyage) - 1;
                                this.enlevement_details.splice(index, 1);
                            }
                        }else{
                            this.numero_voyage = parseInt(this.numero_voyage) - 1;
                            this.enlevement_details.splice(index, 1);
                        }

                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        printBL(id) {
            if (id <= 0) {
                Vue.$toast.open({
                    message: "Veuillez enregistrer cette ligne avant d'imprimer le BL",
                    type: 'error',
                    position: 'top-right'
                    // all of other options may go here
                });
            } else{
                // axios.get(this.BASE_URL + "/suivieenlevementdetail/capturebl/"+id).then((response) => {
                //     if (response.data.error == ''){
                        window.open(this.BASE_URL + "/suivieenlevementdetail/printbl/" + id, '_blank');
                    // }
                // })
            }
        },

        sendWhasApp(id){
            window.open(this.BASE_URL + "/suivieenlevementdetail/capturebl/" + id, '_blank');

        },

        onSubmit() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;

                        link = that.BASE_URL + "/suivieenlevementdetail/addaction";

                        let formualaire = new FormData();
                        formualaire.append('data',JSON.stringify(this.enlevement_details))
                        axios.post(link, formualaire).then(function (response) {
                            console.log(response);
                            that.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                that.loadingData();
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        loadingData() {
            this.getNumeroVoyage(this.$route.params.id_suivie_enlevement);

            this.fetchArticle();
            this.fetchTranspoteur();
            this.fetchNomChauffeur();
            this.fetchNumCamion();
            this.fetchNumRemorque();
            this.fetchNumChauffeur();
            this.fetchDestination();
        }
    },

    watch: {
        // Observer les changements dans les filtres et les données filtrées
        filteredTotals: {
            deep: true,
            handler(newTotals) {
                this.total.total_qte_t = newTotals.total_qte_t;
                this.total.total_nbre_sac = newTotals.total_nbre_sac;
                this.total.total_nbre_big_bag = newTotals.total_nbre_big_bag;
            }
        }
    },
    created: function () {
        this.fetchTransporteur();

    },
    mounted: function () {
        this.loadingData();
        /*if (parseInt(this.$route.params.id_suivie_enlevement) > 0) {

            this.getDossier(this.$route.params.id_suivie_enlevement);
            this.dossier_bl.id_dossier = this.$route.params.id_suivie_enlevement;
        }*/
    }
}
