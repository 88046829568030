// import $ from 'jquery'
import axios from "axios";
import moment from 'moment'
// import moment from 'moment'

export default {
    components: {

    },
    data: function() {
        return {
            pageTitle: "Tableau de bord Conventionnel",
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            loading: false,
            filtre:{
                date_debut: moment().format('DD/MM/YYYY'),
                date_fin: moment().format('DD/MM/YYYY'),
            },
            data:[],
            data_transporteur: [],
        }
    },

    methods: {
        getData(){
            let formulaire = new FormData();
            formulaire.append('date_debut',this.filtre.date_debut);
            formulaire.append('date_fin',this.filtre.date_fin);

            axios.post(this.BASE_URL + '/suivieenlevementdetail/tableaubord',formulaire).then((response) => {

                if (response.data.error === ''){
                    this.data = response.data.data;
                }else{
                    console.log(response.data.error)
                }
            });
            this.recapTransporteur(formulaire);
        },

        recapTransporteur(formulaire) {
            axios.post(this.BASE_URL + '/suivieenlevementdetail/recaptransporteur',formulaire).then((response) => {
                if (response.data.error === ''){
                    console.log(response.data.data)
                    this.data_transporteur = response.data.data;
                }else{
                    console.log(response.data.error)
                }
            })

        },

        // Calculer le rowspan total pour un BL
        getTotalRowSpan(bl) {
            return bl.declarations.reduce((total, decl) => total + decl.rowspan, 0);
        },

        // Formater les nombres
        formatNumber(value) {
            return new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value || 0);
        },

        setFilter() {
            this.getData();
        },
        resetFilter() {
            this.filtre={
                date_debut: '',
                date_fin: ''
            };
            this.setFilter();
        },


    },

    computed: {
        processedData() {
            return this.data.map(bl => {
                // Calculer le rowspan total pour le BL
                const totalRowSpan = bl.declarations.reduce((sum, decl) => sum + decl.rowspan, 0);

                return {
                    ...bl,
                    totalRowSpan,
                    declarations: bl.declarations.map(declaration => {
                        return {
                            ...declaration,
                            marques: declaration.marques.map(marque => {
                                // Calculer les valeurs restantes
                                const restant = {
                                    qte_t: parseFloat(marque.qte_initial) - parseFloat(marque.sortie.qte_t),
                                    nbre_sac: 0 - parseFloat(marque.sortie.nbre_sac),
                                    nbre_big_bag: 0 - parseFloat(marque.sortie.nbre_big_bag)
                                };

                                return {
                                    ...marque,
                                    total: parseFloat(marque.qte_initial),
                                    restant,
                                    isDuplicate: false // Par défaut, aucun doublon
                                };
                            })
                        };
                    })
                };
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function() {
        this.getData();
    }
}
