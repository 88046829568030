import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from "moment";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
// import moment from 'moment'

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function () {
        return {
            pageTitle: "Liste des tresoreries",
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            id_dossier: '',
            nom_bl: '',
            vuetable: {
                moreParams: {
                    critere_date: '',
                    date_debut: '',
                    date_fin: '',
                    is_bl: true,
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'ouverture_date_dossier_formatted',
                        title: 'Date',
                        sortField: 'ouverture_date_dossier_formatted',
                        dataClass: 'text-left',
                        width: '150px'
                    },
                    {
                        name: 'ouverture_bl_numero',
                        title: 'N° BL',
                        sortField: 'ouverture_bl_numero',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'etat_dossier',
                        title: 'Statut',
                        titleClass: 'text-center',
                        width: "110px",
                        dataClass: "text-left"
                    },
                    {
                        name: 'nom_client',
                        title: 'Client',
                        sortField: 'nom_client',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'ouverture_navire',
                        title: 'Navire',
                        sortField: 'ouverture_navire',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'total_solde',
                        title: 'Solde',
                        sortField: 'total_solde',
                        width: '120px',
                        dataClass: 'text-right',
                    },

                    {
                        name: 'actions',
                        title: '-',
                        titleClass: 'text-center',
                        width: "80px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    {field: 'ouverture_date_dossier', direction: 'desc'}
                ],
                css: {
                    table: {
                        // tableClass: 'table table-striped table-bordered table-hovered',
                        tableClass: 'table-responsive w-100 d-block d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Nom de la Tresorerie': 'libelle_ecoterme',
                    'Type de la Tresorerie': 'type_tresorerie',
                    'Solde': 'solde',
                    'Nom du compte': 'nom_compte',
                    'Numero du compte': 'numero_compte',
                    'BIC': 'bic',
                    'IBAN': 'iban',
                    'BANQUE Correspondante': 'banque_correspondante',
                    'Code swift': 'code_swift',
                    'Utilisateur associé': 'nom'

                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                libelle_ecoterme: "",
            },
            crudmodaltitle: "Solder",
            crudSuivieEnlevement: [
                {id: '', id_dossier: '', date_enlevement: '', date_enlevement_formatted: '', ouverture_bl_numero: ''}
            ],
            crudFranchise: [{
                id: '',
                id_compagnie: '',
                ordre: '',
                libeller_franchise: '',
                nbre_jour: 0,
                libelle_ecoterme: '',
                tarif: 0,
            }],
            btn_submit: false,
            criteriacol: '',
            filtre_etat: '',
            filtre: {
                date_debut: '',
                date_fin: '',
            },
            crudSolde: {
                id: '',
                id_dossier: '',
                id_tresorerie: '',
                date_tresorerie: '',
                date_tresorerie_formatted: '',
                libeller: '',
                montant_credit: 0,
                montant_debit: 0,
            },
            liste_tresorerie: [],
        }
    },

    methods: {
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            return (parseFloat(dataItem.etat_dossier) > 0 && parseFloat(dataItem.total_solde) < 0) ? 'bg_cloturer-danger' : (parseFloat(dataItem.etat_dossier) > 0 && parseFloat(dataItem.total_solde) >= 0) ? 'bg_cloturer' : (parseFloat(dataItem.total_solde) < 0) ? 'text-danger' : '';
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        editRow(rowData) {

            this.$router.push("/dossier/edit/" + rowData.id);
        },
        gotoSuivieDossier(rowData) {
            this.$router.push("/dossier/suivi/" + rowData.id);
        },
        suivieEnlevement(rowData) {

            axios.get(this.BASE_URL + '/dossier/getsuivieenlevement/' + rowData.id).then((response) => {
                this.id_dossier = rowData.id;
                this.nom_bl = response.data[0].ouverture_bl_numero;
                this.crudSuivieEnlevement = response.data;
                this.openModalsuivie();
            })
        },
        pushSuivieEnlevement() {
            this.crudSuivieEnlevement.push({
                    id: '',
                    id_dossier: this.id_dossier,
                    date_enlevement: '',
                    date_enlevement_formatted: '',
                    ouverture_bl_numero: this.nom_bl
                }
            )
        },
        spliceSuivieEnlevement(index,id) {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value == true) {
                        if (id > 0) {
                            axios.post(that.BASE_URL + "/suivieenlevement/delete/" + id).then( (response)=> {
                                console.log(response);
                                //this.message_reponse = response.data.msg;
                                if (response.data.error === "") {
                                    Vue.$toast.open({
                                        message: response.data.message,
                                        type: 'success',
                                        position: 'top-right'
                                        // all of other options may go here
                                    });
                                    this.crudSuivieEnlevement.splice(index, 1);

                                } else {
                                    console.log("Erreur");
                                    Vue.$toast.open({
                                        message: response.data.error,
                                        type: 'error',
                                        position: 'top-right'
                                        // all of other options may go here
                                    });
                                }
                            });
                        }else{
                            this.crudSuivieEnlevement.splice(index, 1);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })
        },

        openModalsuivie() {
            this.$bvModal.show("crudmodal_suvie");
        },
        closeModalSuivie() {
            this.$bvModal.hide("crudmodal_suvie");
        },
        downloadZip(rowData) {
            window.open(this.BASE_URL + "/dossier/downloadzip/" + rowData.id, '_blank');
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/dossier/delete/" + rowData.id).then(function (response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },

        getTresorerie() {
            axios.get(this.BASE_URL + '/tresoreries/getalltresorerie').then((response) => {
                this.liste_tresorerie = response.data;
            })
        },

        Solder(rowData) {
            axios.get(this.BASE_URL + '/dossier/getelementtresorerie/' + rowData.id).then((response) => {
                this.crudSolde = response.data.data;
                this.getTresorerie();
                this.crudSolde.date_tresorerie_formatted = moment().format('DD/MM/YYYY');
                this.openModal()
            })
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.filtre_etat = this.filtre_etat;

            this.vuetable.moreParams.critere_date = (this.filtre.date_fin != "" || this.filtre.date_debut != "") ? "ouverture_date_dossier" : "";
            this.vuetable.moreParams.date_fin = (this.filtre.date_fin != "") ? this.filtre.date_fin : "";
            this.vuetable.moreParams.date_debut = (this.filtre.date_debut != "") ? this.filtre.date_debut : "";

            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.filtre_etat = "";
            this.filtre = {
                date_debut: '',
                date_fin: '',
            }

            this.setFilter();
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Nouveau ecoterme";
            this.crudform = {
                id: "",
                libelle_ecoterme: "",
            };
            // console.log(this.crudform);
            this.openModal();
        },
        onSubmit() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;

                        link = that.BASE_URL + "/dossier/reglementsolde";

                        axios.post(link, $("#formulaire").serialize()).then(function (response) {
                            console.log(response);
                            that.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onSubmit_suivie() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;

                        link = that.BASE_URL + "/suivieenlevement/addaction";

                        axios.post(link, $("#formulaire_suivie").serialize()).then(function (response) {
                            console.log(response);
                            that.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModalSuivie();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                setTimeout(() => {
                                    that.$router.push("/dossier/suivieenlevement");
                                },1500)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function () {
            axios.get(this.BASE_URL + "/tresoreries/fetchdata").then(response => {
                console.log(response)
                this.listdata = response.data;
            });
        }
    },

    created: function () {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function () {

    }
}
